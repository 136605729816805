@import '/src/theme/globals';
@import '/src/theme/mixins.scss';

.change-language-bar {
  &_button {
    font-size: 1.3rem;
    cursor: pointer;
    outline: none !important;
    border: none !important;
    height: 37px;
    overflow: hidden;
    transition: transform 0.3s;
    &:hover {
      opacity: 0.5;
    }
  }
}
