@font-face {
  font-family: 'RoobertTRIAL-Regular';
  font-style: normal;
  font-weight: normal;
  src: local('RoobertTRIAL-Regular'),
    url('./theme/font/Roobert/RoobertTRIAL-Regular.woff') format('woff');
}

@font-face {
  font-family: 'RoobertTRIAL-RegularItalic';
  font-style: normal;
  font-weight: normal;
  src: local('RoobertTRIAL-RegularItalic'),
    url('./theme/font/Roobert/RoobertTRIAL-RegularItalic.woff') format('woff');
}

@font-face {
  font-family: 'RoobertTRIAL-Bold';
  font-style: normal;
  font-weight: normal;
  src: local('RoobertTRIAL-Bold'),
    url('./theme/font/Roobert/RoobertTRIAL-Bold.woff') format('woff');
}

@font-face {
  font-family: 'RoobertTRIAL-BoldItalic';
  font-style: normal;
  font-weight: normal;
  src: local('Gordita Thin Italic'),
    url('./theme/font/Roobert/RoobertTRIAL-SemiBold.woff') format('woff');
}

@font-face {
  font-family: 'RoobertTRIAL-Medium';
  font-style: normal;
  font-weight: normal;
  src: local('RoobertTRIAL-Medium'),
    url('./theme/font/Roobert/RoobertTRIAL-Medium.woff') format('woff');
}

@font-face {
  font-family: 'RoobertTRIAL-MediumItalic';
  font-style: normal;
  font-weight: normal;
  src: local('RoobertTRIAL-MediumItalic'),
    url('./theme/font/Roobert/RoobertTRIAL-MediumItalic.woff') format('woff');
}

@font-face {
  font-family: 'Iskry Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Iskry Regular'),
    url('./theme/font/Iskry Regular.woff') format('woff');
}

/* @font-face {
  font-family: 'RoxboroughCF-Regular';
  font-style: normal;
  font-weight: normal;
  src: local('RoxboroughCF-Regular'),
    url('./theme/font/Roxborough/RoxboroughCF-Regular.woff') format('woff');
} */

@font-face {
  font-family: 'RoxboroughCF-Regular';
  font-style: normal;
  font-weight: normal;
  src: local('RoxboroughCF-Regular'),
    url('./theme/font/Roxborough/RoxboroughCF-RegularItalic.woff')
      format('woff');
}
