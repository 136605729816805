@import '/src/theme/globals';
@import '/src/theme/mixins.scss';
@import '/src/theme/keyframes.scss';

.title-button {
  &--title {
    font-size: 4.9rem;
    position: relative;
    cursor: pointer;
    transition: transform 0.3s;
    font-family: 'Iskry Regular';
    &:hover {
      transform: translate(0, -4px);
    }
    @include for-phone-only {
      font-size: 45px;
      display: flex;
      margin-left: 8%;
    }
  }
  &--number {
    font-size: 1.9rem;
    color: $accent-color;
    padding-left: 1.5px;
    @include for-phone-only {
      font-size: 10px;
      top: 5px;
      right: 20px;
    }
  }
  &--disabled {
    color: $opacity-grey;
  }
}
