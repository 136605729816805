@import '/src/theme/globals';
@import '/src/theme/mixins.scss';
@import '/src/theme/keyframes.scss';

.nav-bar {
  position: fixed;
  width: 100vw;
  height: 80px;
  z-index: 2;
  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: space-between;
  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: $secondary-color;
    margin-left: 25px;
  }
  &__point {
    font-family: $bold-font;
    font-size: 10rem;
    margin-top: -60px;
  }
  &__message {
    font-size: 1.1rem;
    padding-left: 10px;
    width: 125px;
  }
  &__letters {
    font-size: 2rem;
    padding-right: 100px;
    @include for-phone-only {
      font-size: 1.3rem;
      padding-right: 0px;
    }
  }
  &__title {
    font-size: 1.3rem;
    z-index: 2;
    span {
      font-size: 1.3rem;
      margin-left: -20px;
      z-index: 2;
    }
    @include for-phone-only {
      display: none;
    }
  }
  &__buttons {
    width: fit-content;
    display: flex;
    align-items: center;
    padding-right: 50px;
  }
}
