@import '/src/theme/globals';
@import '/src/theme/mixins.scss';

* {
  // background: $primary-color;
  font-family: $regular-font;
  padding: 0;
  margin: 0;
  font-size: 10px;
}

body,
html {
  visibility: hidden;
  scroll-behavior: smooth;
  ::selection {
    color: $accent-color;
  }
  background: $primary-color;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
  scroll-behavior: smooth;
}

body::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.mobile-container {
  display: none;
  @include for-phone-only {
    display: block;
  }
}
