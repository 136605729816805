@import '/src/theme/globals';
@import '/src/theme/mixins.scss';
@import '/src/theme/keyframes.scss';

.contact-page {
  &__content {
    height: 100vh;
    display: none;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    @include for-phone-only {
      justify-content: center;
    }
  }
  &__info {
    min-height: 35vh;
    padding: 10px 3%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
  }
  &__text {
    margin-top: 9%;
  }
  &__title {
    font-size: 6.5rem;
    color: $secondary-color;
    text-align: center;
    position: relative;
    @include for-phone-only {
      font-size: 3.5rem;
    }
    &--content {
      display: flex;
      justify-content: center;
      align-items: center;
      @include for-phone-only {
        margin: 0 10px;
      }
    }
    &--font {
      font-family: $second-regular-italic-font;
      font-size: 8rem;
      @include for-phone-only {
        font-size: 5rem;
      }
    }
  }
}
