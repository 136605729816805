@import '/src/theme/globals';
@import '/src/theme/mixins.scss';
@import '/src/theme/keyframes.scss';

.title {
  margin-top: 25px;
  font-size: 47px;
  flex-wrap: wrap;
  transform: translate(0, 500px);
  @include animation('showText 900ms 200ms forwards');
  @include for-phone-only {
    font-size: 33px;
  }
}
