@import '/src/theme/globals';
@import '/src/theme/mixins.scss';
@import '/src/theme/keyframes.scss';

.photos-page {
  &__content {
    display: none;
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    gap: 3px;
    justify-content: center;
  }

  &__item {
    flex: 1 1 calc(30% - 10px);
    margin: 2px;
    box-sizing: border-box;

    img {
      width: 100%;
      height: auto;
      display: block;
    }
  }
}
