@import '/src/theme/globals';
@import '/src/theme/mixins.scss';
@import '/src/theme/keyframes.scss';

.loading-animation {
  &-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    @include animation('fadeOut 200ms 3500ms forwards');
  }
  &-text {
    font-size: 85px;
    height: 100px;
    font-family: $bold-font;
  }
  &-a-1 {
    transform: translate(0, 300px);
    @include animation('showText 900ms 100ms forwards');
  }
  &-a-2 {
    transform: translate(0, 300px);
    @include animation('showText 900ms 200ms forwards');
  }
  &-a-3 {
    transform: translate(0, 300px);
    @include animation('showText 900ms 300ms forwards');
  }
  &-a-4 {
    transform: translate(0, 300px);
    @include animation('showText 900ms 400ms forwards');
  }
  &-a-5 {
    transform: translate(0, 300px);
    @include animation('showText 900ms 500ms forwards');
  }
  &-a-6 {
    transform: translate(0, 300px);
    @include animation('showText 900ms 600ms forwards');
  }
}
