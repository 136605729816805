@import '/src/theme/globals';
@import '/src/theme/mixins.scss';
@import '/src/theme/keyframes.scss';

.button {
  background: none;
  outline: inherit;
  border: 1px solid $secondary-color;
  box-sizing: border-box;
  border-radius: 25px;
  padding: 9px 25px;
  font-size: 1.1rem;
  cursor: pointer;
  margin-right: 15px;
  transition: transform 0.3s;
  color: $secondary-color;
  @include for-phone-only {
    font-size: 10px;
    padding: 9px;
    margin: 5px;
  }
  &:hover {
    transform: translate(0, -3px);
  }
  &:active {
    top: 3px;
    box-shadow: none;
  }
  &__disabled {
    border: 1px solid $white-grey;
    color: $white-grey;
    cursor: not-allowed;
  }
  &__active {
    background: $secondary-color;
    color: $primary-color;
  }
}
