@import '/src/theme/globals';
@import '/src/theme/mixins.scss';

.link-button {
  font-size: 13px;
  padding-right: 35px;
  color: $secondary-color;
  text-decoration: none;
  background: $primary-color;
  transition: all 280ms ease-in-out;
  &:hover,
  &:active {
    transform: translate(0, -5px);
    font-family: $medium-font;
  }
  &:after,
  &:before {
    transition: all 280ms ease-in-out;
  }
  &:hover:after,
  &:hover:before {
    transition: width 350ms ease-in-out;
  }
  @include for-phone-only {
    font-size: 10px;
  }
}
