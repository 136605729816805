@import '/src/theme/globals';
@import '/src/theme/mixins.scss';
@import '/src/theme/keyframes.scss';

.block-text {
  &__info {
    &--content {
      display: flex;
      padding: 5px;
      max-width: fit-content;
      margin-bottom: 20px;
    }
    &--title {
      font-size: 1.3rem;
      color: $grey-color;
      width: 100px;
    }
    &--text {
      display: flex;
      color: $secondary-color;
      text-decoration: none;
      font-size: 1.2rem;
      padding-bottom: 3px;
    }
  }
}
