@import '/src/theme/globals';
@import '/src/theme/mixins.scss';
@import '/src/theme/keyframes.scss';

.work-page {
  &__content {
    height: 100vh;
    display: none;
    flex-direction: column;
    justify-content: center;
    @include for-phone-only {
      height: inherit;
    }
  }
  &__projects {
    display: flex;
    width: fit-content;
    overflow-x: scroll;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    @include for-phone-only {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    &--container {
      display: flex;
      padding: 20px 20px 0px 20px;
      @include for-phone-only {
        flex-direction: column;
      }
    }
    &--content {
      display: flex;
      padding: 3px;
      width: 12vw;
      @include for-tablet-landscape-up {
        width: 14vw;
      }
      @include for-phone-only {
        display: flex;
        width: 90%;
      }
    }
    &--year {
      width: 6vw;
      display: flex;
      padding: 3px 0 0 12px;
      @include for-tablet-landscape-up {
        width: 9vw;
      }
      @include for-phone-only {
        display: flex;
        width: 100%;
      }
    }
    &--image {
      margin-right: 12px;
      padding-top: 10px;
      height: fit-content;
      cursor: none;
      @include for-phone-only {
        width: 75%;
      }
      img {
        width: 100%;
        padding: 5px;
      }
    }
  }
  &__title {
    font-size: 7rem;
    letter-spacing: -2.5px;
    color: $secondary-color;
    text-align: center;
    position: relative;
    width: 500px;
    height: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    line-height: 0.1;
    @include for-phone-only {
      font-size: 6rem;
      width: 300px;
      height: 110px;
      margin-top: 8px;
    }
    &--content {
      display: flex;
      justify-content: center;
      align-items: center;
      @include for-phone-only {
        flex-direction: column;
        height: 190px;
      }
    }
    &--font {
      font-family: $second-regular-italic-font;
      font-size: 7rem;
      padding-right: 12px;
      letter-spacing: -3.5px;
      @include for-phone-only {
        font-size: 6rem;
      }
    }
    &--subtitle {
      padding: 0 7%;
      text-transform: uppercase;
      font-size: 1.1rem;
      text-align: center;
      letter-spacing: -0.03rem;
      @include for-phone-only {
        font-size: 1.1rem;
      }
    }
  }
}
