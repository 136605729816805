@import '/src/theme/globals';
@import '/src/theme/mixins.scss';
@import '/src/theme/keyframes.scss';

.provisional-page {
  &__content {
    display: none;
    padding-top: 75px;
    flex-direction: column;
    overflow: hidden;
    padding-bottom: 50px;
  }
  &__text {
    font-size: 2.6rem;
    color: $secondary-color;
    letter-spacing: 0.04rem;
    margin: 0 2% 1% 2%;
    @include for-phone-only {
      font-size: 2.2rem;
      margin-bottom: 8%;
    }
  }
  &__project {
    display: flex;
    margin: 0.8% 2% 0.3% 2%;
    align-items: center;
    justify-content: space-between;
    @include for-phone-only {
      margin: 3% 4% 2% 4%;
    }
    &--text {
      font-size: 1.2rem;
      margin-right: 18px;
      font-family: $medium-font;
      padding-bottom: 5px;
    }
    &--link {
      font-size: 1.2rem;
      font-family: $bold-font;
      padding-bottom: 5px;
      color: $white-grey;
      text-decoration: none;
      @include for-phone-only {
        display: none;
      }
    }
    &--year {
      font-size: 1.3rem;
      font-family: $medium-font;
      padding-bottom: 5px;
      text-decoration: none;
      width: 160px;
      text-align: right;
      letter-spacing: 0.05rem;
    }
    &--line {
      margin: 0 2% 0 2%;
    }
    &--stack {
      display: flex;
      width: 300px;
      @include for-phone-only {
        display: none;
      }
      > p {
        font-size: 1.1rem;
        margin-right: 8px;
        font-family: $regular-italic-font;
      }
    }
  }
  &__info {
    width: 300px;
    display: flex;
    align-items: center;
  }
}
