@import '/src/theme/globals';
@import '/src/theme/mixins.scss';
@import '/src/theme/keyframes.scss';

.overflow-wrapper {
  scrollbar-width: none;
}

.overflow-wrapper::-webkit-scrollbar {
  display: none;
}
