@import '/src/theme/globals';
@import '/src/theme/mixins.scss';
@import '/src/theme/keyframes.scss';

.list-pill {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  position: relative;
  z-index: 5;
  pointer-events: none;
  &__content {
    width: 100%;
    padding: 30px 5px;
    display: flex;
    align-items: center;
  }
  &__info {
    display: flex;
    align-items: center;
    padding-bottom: 12px;
  }
  &__number {
    font-size: 1.4rem;
    text-decoration: none;
    color: $white-grey;
    padding-right: 25px;
  }
  &__year {
    padding-right: 12px;
    font-size: 0.9rem;
    text-decoration: none;
    color: $accent-color;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-family: $medium-font;
  }
  &__title {
    font-size: 4.2rem;
    text-decoration: none;
    color: $secondary-color;
    font-family: 'Roxborough CF';
  }
  &__description {
    font-size: 1.15rem;
    text-decoration: none;
    color: $secondary-color;
  }
  &__category {
    font-size: 0.9rem;
    text-decoration: none;
    color: $secondary-color;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-family: $medium-font;
  }
  &__image {
    position: absolute;
    width: 500px;
    height: 320px;
    object-fit: cover;
    transform: translateX(-50%) translateY(-50%);
    top: 50%;
    left: 50%;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    z-index: 100;
  }
}
