@import '/src/theme/globals';
@import '/src/theme/mixins.scss';
@import '/src/theme/keyframes.scss';

.custom-cursor {
  position: fixed;
  background-color: $secondary-color;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  z-index: 999;
  pointer-events: none;
  transition: transform 350ms ease;
  transform: translate(-50%, -50%) scale(0.3);

  &.onHoverText {
    mix-blend-mode: difference;
    background-color: $primary-color;
    transform: translate(-50%, -50%) scale(1);
  }

  &.hovered {
    border: 1px solid $primary-color;
    background-color: transparent;
    transform: translate(-50%, -50%) scale(1);
  }

  &.loading {
    background-color: $secondary-color;
    transform: translate(-50%, -50%) scale(0.1);
  }

  &.cursor {
    width: 125px;
    height: 30px;
    border-radius: 16px;
    transform: translate(6%, -19%) scale(1);
  }

  &.navBarLogo {
    border: 1px solid $secondary-color;
    background: none;
    transition: transform 200ms ease;
    transform: translate(-50%, -50%) scale(0.9);
  }

  &__text {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    margin-left: 10px;
    font-size: 2.4rem;

    &.hovered {
      display: none;
    }

    &.loading {
      display: inherit;
    }

    &.cursor {
      display: inherit;
      z-index: 1000;
      width: 125px;
      font-size: 1.4rem;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $primary-color;
    }
  }
}
