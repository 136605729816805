@import '/src/theme/globals';
@import '/src/theme/mixins.scss';
@import '/src/theme/keyframes.scss';

.link-button {
  &__text {
    font-size: 20px;
    position: relative;
    display: inline-block;
    color: $secondary-color;
    overflow: hidden;
    cursor: pointer;
    background: linear-gradient(
      to right,
      $grey-color,
      $grey-color 50%,
      $secondary-color 50%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 200% 100%;
    background-position: 100%;
    transition: background-position 275ms ease;
    transform: translate(0, 500px);
    @include animation('showText 900ms 600ms forwards');
    @include for-phone-only {
      font-size: 16px;
    }
    &:hover {
      background-position: 0 100%;
      cursor: pointer;
    }
  }
}
