@import '/src/theme/globals';
@import '/src/theme/mixins.scss';
@import '/src/theme/keyframes.scss';

.counter {
  &_container {
    overflow: hidden;
    &-label {
      font-size: 10em;
      margin-bottom: -20px;
      transform: translate(0, 500px);
      @include animation('showText 900ms 200ms forwards');
      @include for-tablet-landscape-up {
        font-size: 8.5em;
        margin-bottom: 0px;
      }
      @include for-phone-only {
        font-size: 6.5em;
        padding: 10px 50px;
        line-height: 53px;
        margin-bottom: 0px;
      }
    }
  }
  &_content {
    display: flex;
    justify-content: center;
    &-number {
      padding: 0px 5px;
      color: $accent-color;
      font-size: 20px;
      transform: translate(0, 100%);
      @include animation('showText 900ms 600ms forwards');
      @include for-phone-only {
        font-size: 2em;
      }
    }
    &-text {
      padding: 8px 5px;
      text-transform: uppercase;
      font-size: 16px;
      transform: translate(0, 100%);
      letter-spacing: 2px;
      @include animation('showText 900ms 600ms forwards');
      @include for-phone-only {
        font-size: 2em;
      }
    }
    &-interval {
      font-size: 12px;
      color: $secondary-color;
      transform: translate(0, 100%);
      @include animation('showText 900ms 600ms forwards');
      @include for-phone-only {
        font-size: 13px;
      }
    }
  }
}
