@import '/src/theme/globals';
@import '/src/theme/mixins.scss';
@import '/src/theme/keyframes.scss';

.year-text {
  font-size: 2.5rem;
  margin-right: 20px;
  padding-top: 10px;
  flex-grow: 1;
  &--font {
    font-family: $second-regular-italic-font;
    margin-right: 2px;
  }
}
