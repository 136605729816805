@import '/src/theme/globals';
@import '/src/theme/mixins.scss';
@import '/src/theme/keyframes.scss';

.not-found-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 55%;
  height: 100vh;
  overflow: hidden;
  width: 50%;
  @include for-phone-only {
    width: 100vw;
    margin-left: 0%;
    padding: 0 30px;
    height: 100vh;
  }
  &__title {
    font-size: 30px;
  }
  &__text {
    overflow: hidden;
    font-size: 12px;
    width: 60%;
  }
}
