@import '/src/theme/globals';
@import '/src/theme/mixins.scss';
@import '/src/theme/keyframes.scss';

.playground-page {
  &__content {
    flex-direction: column;
    display: none;
    z-index: 1;
    max-height: 100vh;

    @include for-phone-only {
      padding-top: 80px;
    }
  }
  &__title {
    font-size: 9rem;
    color: $secondary-color;
    text-align: center;
    position: relative;
    @include for-phone-only {
      font-size: 6rem;
      line-height: 55px;
    }
    &--font {
      font-family: $second-regular-italic-font;
    }
  }
  &__text {
    font-size: 2.5rem;
    color: $secondary-color;
    text-align: center;
    margin: 12% 30% 0px 30%;
    letter-spacing: 0.04rem;
    @include for-tablet-landscape-up {
      font-size: 2.3rem;
      margin: 14% 20% 0px 20%;
    }
    @include for-phone-only {
      font-size: 1.5rem;
      margin: 10px 8% 0px 8%;
    }
    &--small {
      font-size: 1.4rem;
      letter-spacing: 0rem;
      position: fixed;
      bottom: 3%;
      left: 45%;
      z-index: 100;
      @include for-phone-only {
        bottom: 3%;
        left: 40%;
        font-size: 1.2rem;
      }
    }
  }
  &__images--container {
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.size-large {
  flex-basis: calc(66.666% - 5px);
}

.size-medium {
  flex-basis: calc(50% - 5px);
}

.size-small {
  flex-basis: calc(33.333% - 5px);
}
