@import '/src/theme/globals';
@import '/src/theme/mixins.scss';
@import '/src/theme/keyframes.scss';

.menu {
  &__content {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    &--top {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &--background {
      width: 100%;
      height: 0px;
      position: absolute;
      z-index: 5;
      background-color: $secondary-color;
      display: flex;
      justify-content: center;
      &--title {
        color: $primary-color;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        opacity: 0;
        font-size: 12.8rem;
        margin-top: 100px;
      }
    }
    &--menu {
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      height: 120px;
      z-index: 100;
      @include for-phone-only {
        display: none;
      }
      &--text {
        z-index: 10;
        text-decoration: none;
        color: $secondary-color;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background: none;
        outline: none;
        border: none;
        p {
          color: $grey-color;
          font-size: 1.2rem;
          padding-bottom: 5px;
        }
      }
    }
  }
}
