@import '/src/theme/globals';
@import '/src/theme/mixins.scss';
@import '/src/theme/keyframes.scss';

.image-card {
  &__container {
    display: flex;
    flex-direction: column;
    flex-basis: calc(33.333% - 32px);
    margin-bottom: 16px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    background-color: white;

    @include for-phone-only {
      padding: 0px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  &__year {
    font-size: 2.4rem;
    margin-right: 20px;
    padding-top: 10px;
    flex-grow: 1;
    &--font {
      font-family: $second-regular-italic-font;
      margin-right: 2px;
    }
  }

  &__gif,
  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;

    @include for-tablet-landscape-up {
      width: 95vw;
    }
    @include for-phone-only {
      width: 95vw;
    }
    &--content {
      display: flex;
      padding-bottom: 15px;
      @include for-phone-only {
        width: 90%;
      }
    }
    &--info {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding: 8px 4px 0 4px;
    }
    &--text {
      font-size: 1.4rem;
      @include for-tablet-landscape-up {
        font-size: 1.1rem;
      }
    }
  }

  &__image-on-hover {
    width: 32vw;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity ease-in 1s;
    &:hover {
      opacity: 1;
    }
  }
}
