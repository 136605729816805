@import '/src/theme/globals';
@import '/src/theme/mixins.scss';
@import '/src/theme/keyframes.scss';

.home-page {
  &__content {
    height: 100vh;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  &__title {
    font-size: 11.2rem;
    color: $secondary-color;
    letter-spacing: 0.04rem;
    text-align: center;
    position: relative;
    @include for-phone-only {
      font-size: 7rem;
      line-height: 65px;
    }
    &--font {
      font-family: $second-regular-italic-font;
    }
    &--small {
      font-size: 1.5rem;
      position: absolute;
      top: 30px;
      @include for-phone-only {
        top: 40px;
        right: 55px;
        font-size: 1.2rem;
      }
    }
  }
  &__text {
    font-size: 2.5rem;
    color: $secondary-color;
    text-align: center;
    margin: 30px 30% 0px 30%;
    letter-spacing: 0.04rem;
    @include for-tablet-landscape-up {
      font-size: 2.3rem;
      margin: 30px 20% 0px 20%;
    }
    @include for-phone-only {
      font-size: 1.5rem;
      margin: 25px 8% 0px 8%;
    }
  }
}
