// GLOBAL COLORS
$primary-color: #ebebeb;
$secondary-color: #1c1c1c;
$accent-color: #c53a13;
$grey-color: #868686;
$white-grey: #d1d1d1;
$opacity-grey: #f1f0f0;

$padding-top-page: 130px;
$padding-page: 0 25px;

// Roobert
$regular-font: 'RoobertTRIAL-Regular';
$regular-italic-font: 'RoobertTRIAL-RegularItalic';
$thin-font: 'Gordita Thin';
$thin-italic-font: 'Gordita Thin Italic';
$light-font: 'Gordita Light';
$light-italic-font: 'Gordita Light Italic';
$medium-font: 'RoobertTRIAL-Medium';
$medium-italic-font: 'RoobertTRIAL-MediumItalic';
$bold-font: 'RoobertTRIAL-Bold';
$bold-italic-font: 'RoobertTRIAL-BoldItalic';
$black-font: 'Gordita Black';
$black-italic-font: 'Gordita Black Italic';
$ultra-font: 'Gordita Ultra';
$ultra-italic-font: 'Gordita Ultra Italic';

// Roxborough
$second-regular-italic-font: 'RoxboroughCF-Regular';
