@import '/src/theme/globals';
@import '/src/theme/mixins.scss';
@import '/src/theme/keyframes.scss';

.footer {
  position: fixed;
  bottom: 0;
  width: 100vw;
  z-index: 2;
  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: space-between;
  padding-bottom: 12px;
  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: $secondary-color;
    padding: 0 20px 0 25px;
  }
  &__message {
    font-size: 1.1rem;
    padding-left: 10px;
    width: 125px;
    &--link {
      color: $secondary-color;
      padding-right: 15px;
      text-decoration: none;
      font-size: 1.2rem;
    }
  }
}
